<template>
  <div>
    <footer
      class="tw-m-4 tw-flex tw-flex-col tw-justify-center tw-text-xs tw-leading-4.5 tw-text-earl-600"
    >
      <div class="tw-text-center">
        This help desk is powered by
        <a
          href="https://www.spiceworks.com/free-help-desk-software/"
          class="tw-cursor-pointer tw-text-earl-600 tw-underline"
          >Spiceworks</a
        >
        to track issues and get all your requests sorted in a snap.
      </div>
      <span class="tw-text-center">
        <a
          href="https://www.spiceworks.com/privacy/"
          class="tw-mx-0.5 tw-cursor-pointer tw-text-earl-600 tw-underline"
          >Privacy Policy</a
        >
        &#183;
        <a
          href="https://www.ziffdavis.com/terms-of-use/"
          class="tw-mx-0.5 tw-cursor-pointer tw-text-earl-600 tw-underline"
          >Terms of Use</a
        >
        &#183;
        <a
          href="https://www.spiceworks.com/privacy/cookies/"
          class="tw-mx-0.5 tw-cursor-pointer tw-text-earl-600 tw-underline"
          >Cookie Policy</a
        >
        &#183;
        <a
          href="https://www.spiceworks.com/accessibility-statement/"
          class="tw-mx-0.5 tw-cursor-pointer tw-text-earl-600 tw-underline"
          >Accessibility Statement</a
        >
        &#183;
        <a
          href="https://dsar.spiceworks.com/"
          class="tw-mx-0.5 tw-cursor-pointer tw-text-earl-600 tw-underline"
          >Do Not Sell My Personal Information</a
        >
        &#183; &#169; Copyright 2006 - {{ new Date().getFullYear() }} Spiceworks Inc.
      </span>
    </footer>
  </div>
</template>
